import "./index.scss"

import React, {useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import InfiniteScroll from "react-infinite-scroll-component"

import Protected from "../../components/Protected/Protected"
import RolesTable from "./components/RolesTable"
import CreateEditRolePopup from "./components/CreateEditRolePopup"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PERMISSIONS} from "../../constants/permissions"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetRolesQuery} from "../../api/roles"


const Roles = () => {
    const [userMessage, setUserMessage] = useState(null)
    const [showCreateRolePopup, setShowCreateRolePopup] = useState(false)

    const {items, loading, hasMore, loadNextPage, reset} = usePaginatedQuery(useGetRolesQuery)

    return (
        <>
            <Helmet>
                <title> Roles - Newsoft Inside </title>
            </Helmet>
            <div>
                {userMessage &&
                    <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                }
            </div>
            <div className="roles-header">
                <Protected permissions={[PERMISSIONS.WRITE_ROLE]}>
                    <div className="roles-header-button">
                        <SmallButton onClick={() => setShowCreateRolePopup(true)}> add new role </SmallButton>
                    </div>
                </Protected>
            </div>

            <InfiniteScroll
                pageStart={0}
                next={loadNextPage}
                hasMore={hasMore}
                loader={<CircularProgress key="circular-progress"/>}
                dataLength={items.length}
                scrollableTarget={"main-content"}
            >
                {loading ? <StyledCircularProgress/> :
                    <div className="roles-content">
                        <RolesTable
                            data={items}
                            refreshTable={reset}
                            setUserMessage={setUserMessage}
                        />
                    </div>
                }
            </InfiniteScroll>

            <Popup open={showCreateRolePopup} closeOnDocumentClick={false} onClose={() => setShowCreateRolePopup(false)} modal>
                {close => (
                    <CreateEditRolePopup
                        create={true}
                        refreshTable={reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default Roles
