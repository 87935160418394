import { useNavigate, useLocation } from "react-router-dom"
import {ProfileRoute} from "../routes"

export const useCustomHistory = () => {
    const navigate = useNavigate()
    const location = useLocation()

    return {
        ...history,
        location,
        goBack: () => navigate(-1),
        push: (pathname, state, activeTab) => {
            state = state || {}
            state.activeTab = activeTab || location.state?.activeTab || ProfileRoute.path
            navigate(pathname, { state })
        }
    }
}
