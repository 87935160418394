import "./index.scss"

import React, {useState} from "react"
import {useSelector} from "react-redux"
import Helmet from "react-helmet"
import CalendarCard from "./components/CalendarCard"
import {useGetPublicHolidayCalendarsQuery} from "../../api/publicHolidayCalendars"
import AddRoundedIcon from "@mui/icons-material/AddRounded"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import {PERMISSIONS} from "../../constants/permissions"
import Protected from "../../components/Protected/Protected"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"
import EventsSidePanel from "./components/EventsSidePanel"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

const PublicHolidayCalendars = () => {
    const { data: calendars, isLoading } = useGetPublicHolidayCalendarsQuery()
    const {hasPermission} = useSelector((state) => state.profile)
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)
    const [selectedCalendarId, setSelectedCalendarId] = useState(null)
    const [userMessage, setUserMessage] = useState(null)

    const handleCalendarClick = (calendar) => {
        setSelectedCalendarId(calendar.id)
        setIsSidePanelOpen(true)
    }

    const handleAddCalendar = () => {
        setSelectedCalendarId(null)
        setIsSidePanelOpen(true)
    }

    const handleClosePanel = () => {
        setIsSidePanelOpen(false)
        setSelectedCalendarId(null)
    }

    const selectedCalendar = calendars?.find((calendar) => calendar.id === selectedCalendarId)

    return (
        <>
            <Helmet>
                <title> Public Holiday Calendars - Newsoft Inside </title>
            </Helmet>
            <div>
                {userMessage &&
                    <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                }
            </div>
            <div className="calendars">
                {isLoading ? <StyledCircularProgress/> :
                    <div className="calendars-content">
                        {calendars.map((calendar) =>
                            <CalendarCard
                                key={calendar.id}
                                calendar={calendar}
                                onClick={() => handleCalendarClick(calendar)}
                            />
                        )}
                        <Protected permissions={[PERMISSIONS.WRITE_CALENDAR_EVENTS]}>
                            <div onClick={handleAddCalendar} className="calendars-content-card add-calendar">
                                <AddRoundedIcon/>
                            </div>
                        </Protected>
                        {!isLoading && calendars.length === 0 && !hasPermission(PERMISSIONS.WRITE_CALENDAR_EVENTS) &&
                            <EmptyResult/>}
                    </div>
                }
                <EventsSidePanel
                    calendar={selectedCalendar}
                    setCalendarId={setSelectedCalendarId}
                    isOpen={isSidePanelOpen}
                    onClose={handleClosePanel}
                    setUserMessage={setUserMessage}
                    loading={isLoading}
                />
            </div>
        </>
    )
}

export default PublicHolidayCalendars
