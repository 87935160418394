export const VERSION = "0.1.0"

export const API_V1_URL = `${process.env.REACT_APP_API_URL}/api/v1`
export const ANALYTICS_TRACKER_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID

export const USER_ROLES = {
    ADMIN: "ADMIN",
    MANAGER: "MANAGER",
    USER: "USER"
}

export const SALARY_TYPES = {
    PER_HOUR: "per hour",
    PER_MONTH: "per month",
    FIXED_PRICE: "per month (fixed price)"
}

export const SETTINGS = {
    ACCESS_TO_TIME_TRACKER: "access_to_time_tracker",
    MAX_TIME_OFFS_TO_TRANSFER: "max_time_offs_to_transfer",
    DEFAULT_TIME_OFFS_PER_YEAR: "default_day_offs_per_year",
    MAX_TIME_OFFS_PER_YEAR: "max_day_offs_per_year",
    TIME_OFF_FOR_EACH_WORKING_YEAR: "time_off_for_each_working_year",
    UPCOMING_PERFORMANCE_REVIEW: "upcoming_performance_review_days_count",
    MAX_DAYS_STORE_NOTIFICATIONS: "max_days_store_notifications",
    ACCESS_TO_EDIT_BONUS: "access_to_edit_bonus",
    WEBSITE_THEME: "website_theme",
    NEWCOINS_ENABLED: "newcoins_enabled",
    SHOP_ENABLED: "shop_enabled",
    AUTO_GIFT_BIRTHDAY: "auto_gift_birthday",
    AUTO_GIFT_ANNIVERSARY: "auto_gift_anniversary",
    AUTO_GIFT_LOGGED_HOURS: "auto_coins_monthly_for_logged_hours",
}

export const PAYMENTS_TABS = {
    SALARIES: "Salaries",
    BONUSES: "Bonuses",
    NEWCOINS: "Newcoins",
}

export const CATEGORIES = {
    ALL: "All categories",
    LAPTOP: "Laptop",
    MONITOR: "Monitor",
    HEADPHONES: "Headphones",
    OTHER: "Other",
}

export const PERFORMANCE_REVIEW_DATE_CHOICE = [
    {value: 3, label:"in 3 months"},
    {value: 6, label:"in 6 months"},
    {value: 12, label:"in 1 year"},
]

export const WEBSITE_THEME_OPTIONS = [
    {value: "Standard", label:"Standard"},
    {value: "New Year", label:"New Year"},
    {value: "Easter", label: "Easter"},
]

export const WEBSITE_THEMES ={
    STANDARD: "Standard",
    NEW_YEAR: "New Year",
    EASTER: "Easter",
}

export const ENGLISH_LEVELS = [
    "Elementary", "Pre-Intermediate", "Intermediate", "Upper-Intermediate", "Advanced", "Native",
]

export const EMPLOYMENT_TYPES = [
    "Full-time", "Part-time",
]

export const EMPLOYEES_FILTERS = {
    UNARCHIVE: "Active",
    ARCHIVE: "Archived"
}

export const STATUS_FILTERS = {
    ALL: "All",
    IN_REVIEW: "In review",
    APPROVED: "Approved",
    DECLINED: "Declined",
}

export const HOURS_TYPE = {
    OfficeWork: "Office work",
    RemoteWork: "Remote work",
    Overtime: "Overtime",
    DayOff: "Day Off",
    UnpaidDayOff: "Unpaid Day Off",
    Illness: "Illness",
}

export const TIME_RECORD_STATUS = {
    IN_REVIEW: "IN REVIEW",
    APPROVED: "APPROVED",
    DECLINED: "DECLINED",
    LOGGED: "LOGGED",
}

export const PRODUCT_STATUS = {
    ALL: "All",
    IN_STOCK: "In stock",
    PRE_ORDER: "Pre-order",
    OUT_OF_STOCK: "Out of stock",
    DELETED: "Deleted",
}

export const PRODUCT_VARIANT_STATUS = {
    IN_STOCK: "In stock",
    PRE_ORDER: "Pre-order",
    OUT_OF_STOCK: "Out of stock",
    DELETED: "Deleted",
    NOT_EXIST: "Not exist",
}

export const HOURS_TYPE_ONLY_TIME_OFF_OPTIONS = [
    {label: HOURS_TYPE.DayOff, value: HOURS_TYPE.DayOff, projectDisable: true},
    {label: HOURS_TYPE.UnpaidDayOff, value: HOURS_TYPE.UnpaidDayOff, projectDisable: true},
    {label: HOURS_TYPE.Illness, value: HOURS_TYPE.Illness, projectDisable: true},
]

export const HOURS_TYPE_TIME_TRACKER_OPTIONS = [
    {label: "Office", value: HOURS_TYPE.OfficeWork, projectDisable: false},
    {label: "Remote", value: HOURS_TYPE.RemoteWork, projectDisable: false},
    {label: "Time Off", value: null, projectDisable: true},
]

export const LOCALSTORAGE_KEYS = {
    SELECTED_HOURS_TYPE: "selected_hours_type",
    USER_ID: "user_id",
    SETTINGS: "settings",
    REPORTS_RANGE: "reports_range",
    USER_REPORTS_RANGE: "user_reports_range",
    PAYMENT_REPORTS_MONTH: "payment_reports_month",
    TIME_RECORDS_RANGE: "time_records_range",
    DRAFT: "draft",
    VIEW_AS_MEMBER_MODE: "view_as_member_mode",
    USER_GREETINGS: "user_greetings",
    ONBOARDING_POPUP_SEEN: "onboarding_popup_seen",
    PIN_TUTORIAL_SEEN: "pin_tutorial_seen",
}

export const FILTER_TYPES = {
    THIS_WEEK: "THIS_WEEK",
    THIS_MONTH: "THIS_MONTH",
    LAST_MONTH: "LAST_MONTH",
    THIS_YEAR: "THIS_YEAR",
    LAST_YEAR: "LAST_YEAR",
    CUSTOM: "CUSTOM",
}

export const WORKING_HOURS_PER_DAY = 8

export const DAYS = {
    SATURDAY: 6,
    SUNDAY: 0,
}

export const MAX_PAGE_SIZE = 1000

export const FAR_FUTURE_DATE = "9999-12-31"

export const PAGE_SIZE = 30

export const MILLISECONDS_PER_SECOND = 1000
export const SECONDS_PER_MINUTE = 60
export const MINUTES_PER_HOUR = 60
export const HOURS_PER_DAY = 24
export const DAYS_PER_WEEK = 7
export const DAYS_PER_YEAR = 365
export const MAX_DAYS_PER_MONTH = 31
export const MONTHS_IN_YEAR = 12
export const MAX_YEAR = 2100

export const SUCCESS_CODE = 200

export const ERROR_CODE = 400

export const INFO_ALERT = "info"

export const ALERT_DURATION = 3000
export const MIN_ALERT_DURATION = 1000

export const LIMIT_NOTIFICATIONS_FOR_BELL = 10

export const GREETINGS_COUNT_DAYS_SHOW = 7 

export const TELEGRAM_URL = "https://t.me/"

export const GREETING_TYPES = {
    anniversary: "anniversary",
    welcome: "welcome",
    birthday: "birthday",
    newcoins: "newcoins",
}

export const PRODUCT_OPTION_TYPE = {
    TEXT: "Text",
    COLOR: "Color"
}

export const DAY_TYPE = {
    PUBLIC_DAY_OFF: "PublicDayOff",
    APPROVED_DAY_OFF: "ApprovedDayOff",
    REQUESTED_DAY_OFF: "RequestedDayOff",
    DECLINED_DAY_OFF: "DeclinedDayOff",
    APPROVED_UNPAID_DAY_OFF: "ApprovedUnpaidDayOff",
    REQUESTED_UNPAID_DAY_OFF: "RequestedUnpaidDayOff",
    DECLINED_UNPAID_DAY_OFF: "DeclinedUnpaidDayOff",
    APPROVED_ILLNESS: "ApprovedIllness",
    REQUESTED_ILLNESS: "RequestedIllness",
    DECLINED_ILLNESS: "DeclinedIllness",
    LOGGED: "Logged",
    PARTIALLY_LOGGED: "PartiallyLogged",
}

export const ANNIVERSARY_GREETING_TYPES = [
    "HAPPY ANNIVERSARY",
    "HAPPY ANNIVERSARY WITH NEWCOINS",
    "HAPPY ANNIVERSARY WITH TIME OFF",
    "HAPPY ANNIVERSARY WITH TIME OFF AND NEWCOINS",
]

export const PERFORMANCE_REVIEW_PERIOD = {
    ONE_MONTH: 1,
    THREE_MONTHS: 3,
}

export const MOBILE_WIDTH = 666

export const TABLET_WIDTH = 768

export const DESKTOP_WIDTH = 1024

export const TIME_TRACKER_SUBMIT_ERROR = {
    intersectTimeOffs: "* submit day off on vacation days, user day offs, weekends and public holidays is not allowed",
    workInFuture: "* submit work and overtime in future is not allowed",
    recordInPast: "* submit time record earlier than {accessToTimeTracker} days is not allowed",
    recordInPastExt: "* It’s mandatory for records submitted beyond a {accessToTimeTracker}-day timeframe to be reviewed by a manager.",
}

export const REQUEST_OVERTIME_COEFFICIENT_ERROR = "You cannot log overtime as you do not have a coefficient."

export const SMILES = ["🤐", "🤑", "😎", "🦾", "😇", "🥳", "🤠", "🤪", "🧐", "🤔", "🤫", "😲", "😦", "😬", "😻", "🙀", "💸", "💵", "💰", "🇺🇦"]
export const NEW_YEAR_SMILES = ["🦌", "🎄", "🐰", "🍭", "🎁", "🎅", "🥂", "🎉", "🛷", "🍾", "☃️", "❄️", "🎊"]
export const EASTER_SMILES = ["🐰", "🐣", "🐇", "🐥", "🥚", "🍭", "🐏"]

export const NUMBER_OF_SMILES = 3

export const CACHED_IMAGES = {
    LogoNS: "/images/logo_newsoft2.svg",
    Food: "/images/food.svg",
    Equipment: "/images/equipment.svg",
    GymPlace: "/images/gym_place.svg",
    Vacations: "/images/vacations.svg",
    SelfImprovement: "/images/self_improvement.svg",
    ChillingRoom: "/images/chilling_room.svg",
    EnglishLessons: "/images/english_lessons.svg",
    FlexibleSchedule: "/images/flexible_schedule.svg",
}

export const HOURS_TYPE_COLORS = [
    {type: "Office work", color: "#F57F17"},
    {type: "Remote work", color: "#F57F17"},
    {type: "Day Off", color: "#82C785"},
    {type: "Unpaid Day Off", color: "#AD7454"},
    {type: "Illness", color: "#79C3DA"},
    {type: "Requested Day Off", color: "#CDE9C5"},
    {type: "Requested Unpaid Day Off", color: "#D2A992"},
    {type: "Requested Illness", color: "#C8EDF8"},
    {type: "Declined Day Off", color: "#D3D2D2"},
    {type: "Declined Unpaid Day Off", color: "#D3D2D2"},
    {type: "Declined Illness", color: "#D3D2D2"},
]

export const FILTER_BY_PROJECTS_NOTE = "This filter shows information without time off and bonuses."

export const DD_MM_YYYY = "DD.MM.YYYY"
export const DD_MM = "DD.MM"
export const DD_MM_YYYY_HH_MM = "DD.MM.YYYY HH:mm"
export const DD_MM_YYYY_HH_MM_SS = "DD.MM.YYYY HH:mm:ss"
export const HH_MM_SS = "HH:mm:ss"
export const HH_MM = "HH:mm"

export const TIME_OFF_PRIORITIES = {
    "ApprovedDayOff": 3,
    "RequestedDayOff": 2,
    "DeclinedDayOff": 1,
    "ApprovedIllness": 3,
    "RequestedIllness": 2,
    "DeclinedIllness": 1,
    "ApprovedUnpaidDayOff": 3,
    "RequestedUnpaidDayOff": 2,
    "DeclinedUnpaidDayOff": 1,
}

export const WORKING_HOURS_STRING = "Office work,Remote work,Overtime"

export const DECIMAL_PATTERN = /^(\d*\.?\d{0,4})$/

export const AUTO_GIVEN_NEWCOINS = [
    {title: "Birth date", amount: 50, reason: "Birthday bonus", setting_name: "birthday"},
    {title: "Anniversary in the company", amount: 70, reason: "Anniversary in the company bonus", setting_name: "anniversary"},
    {title: "For the each working hour", amount: 0.1, reason: "Monthly bonus for the each working hour", setting_name: "logged_hours"},
]

export const USER_TO_GLOBAL_NEWCOINS_SETTINGS = {
    birthday: "auto_gift_birthday",
    anniversary: "auto_gift_anniversary",
    logged_hours: "auto_coins_monthly_for_logged_hours",
}

export const PIN_TUTORIAL_VIDEO_LINK = "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside_pin_template.mov"

export const PRODUCT_LABEL_COLORS = ["#F57F17", "#F74E1E", "#79C3DA", "#82C785", "#AD7454", "#D3D2D2"]

export const DEFAULT_COLOR_PICKER_COLOR = "#F2994A"

export const PURCHASE_REQUEST_STATUS = {
    ALL: "All",
    IN_REVIEW: "In review",
    APPROVED: "Approved",
    DECLINED: "Declined",
}

export const EMPLOYEE_STATUS = {
    ALL: "All",
    CURRENT: "Current",
    FORMER: "Former"
}

export const PRODUCT_OPTION_VALUE_DIVIDER = "|"

export const PRODUCTS_SORT_OPTIONS = {
    PRICE_LOW_TO_HIGH: {label: "Price: Low to High", value: "price"},
    PRICE_HIGH_TO_LOW: {label: "Price: High to Low", value: "-price"},
    NEWEST_FIRST: {label: "Newest First", value: "-created_at"},
    OLDEST_FIRST: {label: "Oldest First", value: "created_at"},
}

export const COUPON_TYPE = {
    ONE_TIME: {label: "One time", value: "ONE_TIME"},
    ONE_TIME_PER_USER: {label: "One time per user", value: "ONE_TIME_PER_USER"},
}

export const COUPON_STATUSES = {
    DEACTIVATED: { label: "Deactivated", value: "DEACTIVATED", color: "#999999" },
    ACTIVE: { label: "Active", value: "ACTIVE", color: "#F57F17" },
    CLAIMED: { label: "Claimed", value: "CLAIMED", color: "#4CAF50" },
    EXPIRED: { label: "Expired", value: "EXPIRED", color: "#999999" },
    PENDING: { label: "Pending", value: "PENDING", color: "#999999" },
}

export const INVALID_COUPON_MESSAGE= "Oops... This coupon wasn`t found. Please try a different one!"

export const COUPON_ERROR_MESSAGES = {
    "Coupon already claimed": "Oops... This coupon has already been claimed. Please try a different one!",
    "Coupon already claimed by user": "Oops... This coupon has already been claimed. Please try a different one!",
    "Coupon is deactivated": "Oops... This coupon is deactivated. Please try a different one!",
    "Coupon is expired": "Oops... This coupon is expired. Please try a different one!",
    "Coupon is invalid": INVALID_COUPON_MESSAGE,
    "Coupon not found": INVALID_COUPON_MESSAGE
}

export const ALLOWED_IMAGE_FORMATS = ["image/jpeg", "image/jpg", "image/png", "image/heic"]
export const NOT_ALLOWED_IMAGE_FORMAT_ERROR_MESSAGE = "Unrecognized image format. Please select a file in JPG, JPEG, PNG, or HEIC format."

export const NODE_ENV_OPTIONS = {
    DEVELOPMENT: "development",
    PRODUCTION: "production",
}

export const NODE_ENV = process.env.REACT_APP_NODE_ENV || NODE_ENV_OPTIONS.DEVELOPMENT

export const INTEGRATION_TYPES = {
    GOOGLE_OOO: "google_ooo",
}