import "./index.scss"

import React, {useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import InfiniteScroll from "react-infinite-scroll-component"

import Protected from "../../components/Protected/Protected"
import EmployeePositionsTable from "./components/EmployeePositionsTable"
import CreateUpdatePositionPopup from "./components/CreateUpdatePositionPopup"

import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {PERMISSIONS} from "../../constants/permissions"
import {useGetEmployeePositionsQuery} from "../../api/employeePositions"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"
import {useTableData} from "../../hooks/common/useTableData"


const EmployeePositions = () => {
    const [userMessage, setUserMessage] = useState(null)
    const [createUpdateEmployeePositionPopup, setCreateUpdateEmployeePositionPopup] = useState(false)

    const {items, loading, params, hasMore, empty, loadNextPage, reset, changeParams } = useTableData(useGetEmployeePositionsQuery, "employeePositions")

    const onSearchChange = (value) =>  changeParams({name: value})

    return (
        <>
            <Helmet>
                <title>Employee Positions - Newsoft Inside</title>
            </Helmet>

            <div className="employee-positions">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="employee-positions-header">
                    <SearchForm defaultValue={params.name || ""} onChange={onSearchChange} debounceMs={500}/>
                    <Protected permissions={[PERMISSIONS.WRITE_EMPLOYEE_POSITION]}>
                        <div className="employee-positions-header-button">
                            <SmallButton onClick={() => setCreateUpdateEmployeePositionPopup(true)}>
                                ADD NEW POSITION
                            </SmallButton>
                        </div>
                    </Protected>
                </div>
                <InfiniteScroll
                    pageStart={1}
                    next={loadNextPage}
                    hasMore={hasMore}
                    dataLength={items.length}
                    loader={<StyledCircularProgress/>}
                    scrollableTarget={"main-content"}
                >
                    {items.length > 0 && (
                        <div className="employee-positions-content">
                            <EmployeePositionsTable
                                employeePositions={items}
                                onSubmit={reset}
                                setUserMessage={setUserMessage}
                            />
                        </div>)
                    }
                    {loading && <StyledCircularProgress/>}
                    {empty && <div className="employee-positions-content-empty-result"><EmptyResult/></div>}
                </InfiniteScroll>
            </div>

            <Popup
                open={createUpdateEmployeePositionPopup}
                closeOnDocumentClick={false}
                onClose={() => setCreateUpdateEmployeePositionPopup(false)}
                modal
            >
                {close => (
                    <CreateUpdatePositionPopup
                        onSubmit={reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>)
}

export default EmployeePositions
