import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import {useMediaQuery} from "react-responsive"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {generateCancelToken} from "../../http"

import {
    receiveNextPagePaymentReports,
    receivePayments,
    receivePaymentReports,
    receivePaymentReportsTotal,
} from "./actions/paymentReportsActions"

import PaymentReportsTable from "./components/PaymentReportsTable"
import SubmitReportPopup from "./components/SubmitReportPopup"
import CreateUpdateRatePopup from "./components/CreateUpdateRatePopup"
import {SensitiveInfo} from "../../components/SensitiveInfo/SensitiveInfo"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import Checkbox from "../../toolkits/Checkbox/Checkbox"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {endOfMonth, formatDate, startOfMonth} from "../../utils/datetime.utils"

import {savePaymentReportsMonth, getSavedPaymentReportsMonth} from "../../utils/localstorage.utils"

import {FILTER_TYPES, CANCEL_TOKEN_TYPES} from "./const"
import {DESKTOP_WIDTH, FILTER_BY_PROJECTS_NOTE, PAGE_SIZE} from "../../constants/other"

import PenIcon from "@mui/icons-material/Create"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import OvertimeInfoPopup from "../Reports/components/OvertimeInfoPopup"
import BonusesInfoPopup from "../Reports/components/BonusesInfoPopup"
import {roundEarn} from "../../utils/other.utils"

import MultipleSelect from "../../toolkits/Multiselect"
import InfoMessage from "../../components/InfoMessage"
import useProjects from "../../hooks/useProjects"
import DeprecatedSingleDatePicker from "../../toolkits/Calendar/DeprecatedSingleDatePicker"

const PaymentReports = () => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const dispatch = useDispatch()

    const {
        total,
        tableLoading,
        payments,
        paymentReports,
        paymentTotal,
        paymentTotalLoading,
    } = useSelector(state => state.paymentReports)

    const [cancelTokens, setCancelTokens] = useState({
        [CANCEL_TOKEN_TYPES.PAYMENTS]: null,
        [CANCEL_TOKEN_TYPES.PAYMENT_REPORTS]: null,
        [CANCEL_TOKEN_TYPES.PAYMENT_REPORTS_TOTAL]: null,
    })

    const thisMonth = new Date()
    const lastMonth = new Date(thisMonth.getFullYear(), thisMonth.getMonth() - 1, 1)

    const defaultMonth = getSavedPaymentReportsMonth() || FILTER_TYPES.THIS_MONTH

    const [currentPage, setCurrentPage] = useState(1)
    const [userMessage, setUserMessage] = useState(null)
    const [searchKey, setSearchKey] = useState()

    const parseFilterType = {
        [FILTER_TYPES.THIS_MONTH]: thisMonth,
        [FILTER_TYPES.LAST_MONTH]: lastMonth,
    }

    const [month, setMonth] = useState({
        type: defaultMonth,
        value: parseFilterType[defaultMonth],
        formattedValue: formatDate(parseFilterType[defaultMonth], "MM/YYYY")
    })
    const [exchangeRate, setExchangeRate] = useState({})
    const [includedUsers, setIncludedUsers] = useState([])
    const [excludedUsers, setExcludedUsers] = useState([])
    const [selectedProjectIds, setSelectedProjectIds] = useState([])

    const {projects} = useProjects()

    const hasProjects = projects.length !== 0

    const [isAllSelected, setIsAllSelected] = useState(false)
    const [isGeneratedReport, setIsGeneratedReport] = useState(null)
    
    const [showSubmitPopup, setShowSubmitPopup] = useState(false)
    const [showCreateRatePopup, setShowCreateRatePopup] = useState(false)
    const [showUpdateRatePopup, setShowUpdateRatePopup] = useState(false)

    const [showOvertimeInfoPopup, setShowOvertimeInfoPopup] = useState(false)
    const [showBonusesInfoPopup, setShowBonusesInfoPopup] = useState(false)
    const [inspectUserId, setInspectUserId] = useState(null)

    const isAllProjectsSelected = selectedProjectIds.length === projects.length || selectedProjectIds.length === 0

    const setters = {
        overtimePopup: setShowOvertimeInfoPopup,
        bonusesPopup: setShowBonusesInfoPopup,
        inspectUser: setInspectUserId
    }

    const dateRange = {
        start_date: formatDate(startOfMonth(month.value)),
        end_date: formatDate(endOfMonth(month.value))
    }

    const setCancelTokenWrapper = (type) => (state) =>
        setCancelTokens(oldState => ({
            ...oldState,
            [type]: state,
        }))

    const isDisabledGenerateReportButton = () => {
        const needToPayTotal = paymentTotal.need_to_pay &&
            Object.keys(paymentTotal.need_to_pay).reduce((result, currency) =>
                result + paymentTotal.need_to_pay[currency], 0)

        return [
            month.value?.getYear() > thisMonth?.getYear(),
            /*(month.value?.getYear() === thisMonth?.getYear() &&
                month.value?.getMonth() >= thisMonth?.getMonth()),*/
            (!isAllSelected
                ? !includedUsers.length
                : excludedUsers.length === total
            ),
            !(isAllProjectsSelected),
            paymentTotalLoading,
            !needToPayTotal,
        ].some(Boolean)
    }

    const isPaidPayment = (userId) =>
        payments.map(payment => payment.user_id).includes(userId)

    const isPrimaryCurrency = (currency) =>
        paymentTotal.currency_iso_codes[currency].primary

    const getTableData = () => {
        const result = isGeneratedReport
            ? !isAllSelected
                ? paymentReports
                    .filter(x => includedUsers.includes(x.user_id))
                : paymentReports
                    .filter(x => !excludedUsers.includes(x.user_id))
            : paymentReports

        return result
            .map(payment => ({
                ...payment,
                is_paid: isPaidPayment(payment.user_id)
            }))
            .filter(payment => isGeneratedReport ? !payment.is_paid : true)
    }

    const getParams = (page) => ({
        page: page,
        size: PAGE_SIZE,
        month: month.formattedValue,
        name_contains: searchKey,
        project_ids: isAllProjectsSelected ? null : selectedProjectIds.toString(),
    })

    const getPaymentReports = (page) => {
        const params = getParams(page || currentPage)
        dispatch(receivePayments({
            params: {month: month.formattedValue},
            cancelToken: generateCancelToken(cancelTokens.payments,
                setCancelTokenWrapper(CANCEL_TOKEN_TYPES.PAYMENTS)),
        }))
        dispatch(receivePaymentReports({
            params,
            cancelToken: generateCancelToken(cancelTokens.paymentReports,
                setCancelTokenWrapper(CANCEL_TOKEN_TYPES.PAYMENT_REPORTS)),
        }))
        setCurrentPage(page + 1)
    }

    const getNextPaymentReports = () => {
        const params = getParams(currentPage)

        dispatch(receiveNextPagePaymentReports(params))
        setCurrentPage(currentPage + 1)
    }

    const refreshPaymentReportsTotal = () => {
        const params = {
            month: month.formattedValue,
            project_ids: isAllProjectsSelected ? null : selectedProjectIds.toString(),
            ...(isAllSelected
                ? {include_users: null, excluded_users: excludedUsers}
                : {include_users: includedUsers, excluded_users: null}
            ),
        }

        dispatch(receivePaymentReportsTotal({
            params,
            cancelToken: generateCancelToken(cancelTokens.paymentReportsTotal,
                setCancelTokenWrapper(CANCEL_TOKEN_TYPES.PAYMENT_REPORTS_TOTAL))
        }))
    }


    useEffect(() => {
        setIncludedUsers([])
        setExcludedUsers([])
        getPaymentReports(1)

        if (month.type !== FILTER_TYPES.CUSTOM) {
            savePaymentReportsMonth(month.type)
        }
    }, [month?.value, selectedProjectIds])

    useEffect(() => {
        if (searchKey !== undefined) {
            getPaymentReports(1)
        }
    }, [searchKey])

    useEffect(() => {
        refreshPaymentReportsTotal()
    }, [isAllSelected, includedUsers.length, excludedUsers.length, month?.value, selectedProjectIds])


    const CalendarInput = ({openCalendar}) => (
        <span
            className={`${month?.type === FILTER_TYPES.CUSTOM
                ? "paym-rep-period-selected t-s3"
                : "t-s4"} paym-rep-period-calendar`
            }
            onClick={openCalendar}
        >
            {month?.type === FILTER_TYPES.CUSTOM
                ? formatDate(month.value, "MMMM Y")
                : "Select month"
            }
        </span>
    )

    return (
        <>
            <Helmet>
                <title> Payment Reports - Newsoft Inside </title>
            </Helmet>
            <div className="paym-rep">
                {/* ALERT MESSAGE */}
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} {...userMessage}/>
                    }
                </div>
                
                {/* HEADER */}
                <div className="paym-rep-header">
                    <div className="paym-rep-header-search">
                        <SearchForm onChange={setSearchKey} debounceMs={500} />
                    </div>
                    <div className={`paym-rep-header-button${isGeneratedReport ? "-ext" : ""}`}>
                        {!isGeneratedReport
                            ?
                            <SmallButton
                                onClick={() => setShowCreateRatePopup(true)}
                                disabled={isDisabledGenerateReportButton()}
                            >
                                {"GENERATE PAYMENT REPORT"}
                            </SmallButton>
                            :
                            <>
                                <SmallButton btnType="secondary" onClick={() => setIsGeneratedReport(false)}>
                                    {"CANCEL"}
                                </SmallButton>

                                <SmallButton onClick={() => setShowSubmitPopup(true)}>
                                    {"SUBMIT AND SEND EMAIL"}
                                </SmallButton>
                            </>
                        }
                    </div>
                </div>

                {/* FILTERS & EXCHANGE RATE */}
                <div className="paym-rep-period-wrapper">
                    <div className="paym-rep-period">
                        {!isGeneratedReport
                            ? <>
                                <span className="paym-rep-period-checkbox">
                                    <Checkbox
                                        checked={isAllSelected}
                                        onChange={() => {
                                            setIsAllSelected(!isAllSelected),
                                            setIncludedUsers([])
                                            setExcludedUsers([])
                                        }}
                                    />
                                    <KeyboardArrowDownIcon className="arrow"/>
                                </span>
                                
                                <span
                                    className={month?.type === FILTER_TYPES.THIS_MONTH ? "paym-rep-period-selected t-s3" : "t-s4"}
                                    onClick={() => setMonth({
                                        type: FILTER_TYPES.THIS_MONTH,
                                        value: thisMonth,
                                        formattedValue: formatDate(thisMonth, "MM/YYYY")
                                    })}
                                >
                                    {"This month"}
                                </span>

                                <span
                                    className={month?.type === FILTER_TYPES.LAST_MONTH ? "paym-rep-period-selected t-s3" : "t-s4"}
                                    onClick={() => setMonth({
                                        type: FILTER_TYPES.LAST_MONTH,
                                        value: lastMonth,
                                        formattedValue: formatDate(lastMonth, "MM/YYYY")
                                    })}
                                >
                                    {"Last month"}
                                </span>

                                <DeprecatedSingleDatePicker
                                    className="single-datepicker-field"
                                    setNewDate={value => {
                                        const newDate = new Date(value.split(".")[2], value.split(".")[1] - 1)
                                        
                                        setMonth({
                                            type: FILTER_TYPES.CUSTOM,
                                            value: newDate,
                                            formattedValue: formatDate(newDate, "MM/YYYY")
                                        })
                                    }}
                                    render={<CalendarInput/>}
                                    onlyMonthPicker
                                />
                            </>
                            : <span className="paym-rep-period-generated t-s1">
                                {formatDate(month?.value, "MMMM Y")}
                            </span>
                        }
                        {isDesktop && hasProjects &&
                            <div className="paym-rep-period-project-filter">
                                {selectedProjectIds.length !== 0 && selectedProjectIds.length !== projects.length &&
                                    <InfoMessage text={FILTER_BY_PROJECTS_NOTE}/>}
                                { projects.length > 1 &&
                                    <>
                                        <span className="paym-rep-period-project-filter-text t-s4">Project:</span>
                                        <MultipleSelect placeholder="Choose the project" loading={tableLoading} options={projects} selectedOptionIds={selectedProjectIds} setSelectedOptionIds={setSelectedProjectIds}/>
                                    </>
                                }
                            </div>
                        }
                    </div>
                    {isGeneratedReport &&
                        <div className="paym-rep-rate t-s1">
                            {paymentTotal.need_to_pay &&
                                !(Object.keys(paymentTotal.need_to_pay).length === 1 &&
                                    isPrimaryCurrency(Object.keys(paymentTotal.need_to_pay)[0])) &&
                            <>
                                {Object.keys(paymentTotal.need_to_pay)
                                    .filter(key =>  !isPrimaryCurrency(key))
                                    .map((currency) => (
                                        `${currency} exchange rate = ${currency in exchangeRate ? exchangeRate[currency] : 0}`
                                    )).join(", ")
                                }
                                <PenIcon className="pen-icon" onClick={() => month?.value && setShowUpdateRatePopup(true)}/>
                            </>
                            }
                            
                        </div>
                    }
                </div>

                {/* MAIN-CONTENT: TABLE */}
                <InfiniteScroll
                    pageStart={0}
                    next={getNextPaymentReports}
                    hasMore={!tableLoading && paymentReports.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={paymentReports.length}
                    scrollableTarget={"main-content"}
                    useWindow={false}
                >
                    {tableLoading ? <div><br/><StyledCircularProgress/></div> :
                        <div className="paym-rep-content">
                            <PaymentReportsTable
                                data={getTableData()}
                                isGeneratedReport={isGeneratedReport}
                                isAllSelected={isAllSelected}
                                setters={setters}
                                includedUsers={{
                                    get: () => includedUsers,
                                    set: setIncludedUsers,
                                }}
                                excludedUsers={{
                                    get: () => excludedUsers,
                                    set: setExcludedUsers,
                                }}
                            />
                        </div>
                    }
                </InfiniteScroll>

                {/* FOOTER */}
                <div className="paym-rep-footer">
                    {paymentTotalLoading
                        ? <div className="paym-rep-footer-loader"><StyledCircularProgress/></div>
                        : <>
                            {paymentTotal.paid && Object.keys(paymentTotal.paid).some(currency => paymentTotal.paid[currency]) && !isGeneratedReport ?
                                <div className={`paym-rep-footer-paid ${isDesktop ? "t-h2" : "t-s3"}`}>
                                    <span>Paid:&nbsp;</span>
                                    <SensitiveInfo>
                                        <span>
                                            {Object.keys(paymentTotal.paid).filter(currency => paymentTotal.paid[currency]).map((currency) => (
                                                `${roundEarn(paymentTotal.paid[currency])} ${currency}`
                                            )).join(" + ")
                                            }
                                        </span>
                                    </SensitiveInfo>
                                </div> : <></>
                            }
                            {paymentTotal.need_to_pay && Object.keys(paymentTotal.need_to_pay).length ?
                                <div className={`paym-rep-footer-need-to-pay ${isDesktop ? "t-h2" : "t-s3"}`}>
                                    <span>Need to pay:&nbsp;</span>
                                    <SensitiveInfo>
                                        <span>
                                            {Object.keys(paymentTotal.need_to_pay).map((currency) => (
                                                `${roundEarn(paymentTotal.need_to_pay[currency])} ${currency}`
                                            )).join(" + ")
                                            }
                                        </span>
                                    </SensitiveInfo>
                                </div> : <></>
                            }
                        </>
                    }
                </div>
            </div>

            <Popup open={showOvertimeInfoPopup} closeOnDocumentClick onClose={() => setShowOvertimeInfoPopup(false)} modal>
                {close => <OvertimeInfoPopup close={close} userId={inspectUserId} dateRange={dateRange}/>}
            </Popup>

            <Popup open={showBonusesInfoPopup} closeOnDocumentClick onClose={() => setShowBonusesInfoPopup(false)} modal>
                {close => <BonusesInfoPopup close={close} userId={inspectUserId} dateRange={dateRange}/>}
            </Popup>

            <Popup open={showSubmitPopup} closeOnDocumentClick={false} onClose={() => setShowSubmitPopup(false)} modal>
                {close => (
                    <SubmitReportPopup
                        month={month.formattedValue}
                        includedUsers={!isAllSelected ? includedUsers : null}
                        excludedUsers={isAllSelected
                            ? excludedUsers.concat(
                                getTableData()
                                    .filter(payment => payment.is_paid)
                                    .map(payment => payment.user_id)
                            )
                            : null
                        }
                        currencies={paymentTotal.currency_iso_codes}
                        exchangeRate={exchangeRate}
                        refreshTable={() => {
                            setIsGeneratedReport(false)
                            setIncludedUsers([])
                            setExcludedUsers([])
                            dispatch(receivePayments({
                                params: {month: month.formattedValue},
                                cancelToken: generateCancelToken(cancelTokens.payments,
                                    setCancelTokenWrapper(CANCEL_TOKEN_TYPES.PAYMENTS))
                            }))
                        }}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

            <Popup
                open={showCreateRatePopup || showUpdateRatePopup}
                closeOnDocumentClick={false}
                onClose={() => {
                    setShowCreateRatePopup(false)
                    setShowUpdateRatePopup(false)
                }}
                modal
            >
                {close => (
                    <CreateUpdateRatePopup
                        create={showCreateRatePopup}
                        currencies={paymentTotal.need_to_pay && paymentTotal.paid &&
                            [...new Set(Object.keys({...paymentTotal.need_to_pay, ...paymentTotal.paid}))].filter(key => !isPrimaryCurrency(key))
                        }
                        primaryCurrency={paymentTotal.primary_currency}
                        exchangeRate={exchangeRate}
                        setExchangeRate={setExchangeRate}
                        setIsGeneratedReport={setIsGeneratedReport}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default PaymentReports
