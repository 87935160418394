import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import InfiniteScroll from "react-infinite-scroll-component"

import {LeftCategories, RightCategories} from "./components/EquipmentCategories"
import EquipmentTable from "./components/EquipmentTable"
import CreateUpdateEquipmentPopup from "./components/CreateUpdateEquipmentPopup"
import Protected from "../../components/Protected/Protected"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PERMISSIONS} from "../../constants/permissions"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetEquipmentQuery} from "../../api/equipment"


const Equipment = () => {
    const [userMessage, setUserMessage] = useState(null)

    const [searchKey, setSearchKey] = useState()
    const [leftCategory, setLeftCategory] = useState({})
    const [rightCategory, setRightCategory] = useState({})

    const [addNewEquipmentPopup, setAddNewEquipmentPopup] = useState(false)

    const {items, loading, loadNextPage, hasMore, changeParams, sort, changeSort, reset, empty} = usePaginatedQuery(
        useGetEquipmentQuery,
        {
            defaultState: {
                params: {
                    ...leftCategory,
                    ...rightCategory,
                    equipment_name_user_name: searchKey,
                }
            }
        })

    useEffect(() => changeParams({
        ...leftCategory,
        ...rightCategory,
        equipment_name_user_name: searchKey,
    }, false), [leftCategory, rightCategory, searchKey])

    return (
        <>
            <Helmet>
                <title>Equipment - Newsoft Inside</title>
            </Helmet>

            <div className="equipment">
                {userMessage &&  <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>}
                <div className="equipment-header">
                    <div className="equipment-header-search">
                        <SearchForm onChange={setSearchKey} debounceMs={500}/>
                        <Protected permissions={[PERMISSIONS.WRITE_EQUIPMENT]}>
                            <div className="equipment-header-search-button">
                                <SmallButton onClick={() => {
                                    setAddNewEquipmentPopup(true)
                                }}> ADD NEW EQUIPMENT </SmallButton>
                            </div>
                        </Protected>
                    </div>
                    <div className="equipment-header-categories">
                        <LeftCategories setCategory={setLeftCategory}/>
                        <Protected permissions={[PERMISSIONS.READ_EQUIPMENT]}>
                            <RightCategories category={rightCategory} setCategory={setRightCategory}/>
                        </Protected>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                    scrollableTarget={"main-content"}
                >
                    <div className="equipment-content">
                        <EquipmentTable
                            equipment={items}
                            ordering={sort}
                            setOrdering={changeSort}
                            tableLoading={loading}
                            refreshTable={reset}
                            setUserMessage={setUserMessage}
                            empty={empty}
                        />
                    </div>
                    {loading && <div className="equipment-content-progress"><StyledCircularProgress/></div>}
                </InfiniteScroll>
            </div>

            <Popup open={addNewEquipmentPopup} closeOnDocumentClick={false} onClose={() => setAddNewEquipmentPopup(false)} modal>
                {close => (
                    <CreateUpdateEquipmentPopup
                        refreshTable={reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>)
}

export default Equipment
