import {isEqual, isUndefined, omitBy} from "lodash"
import {useCallback, useEffect, useMemo, useState} from "react"
import {PAGE_SIZE} from "../../constants/other"



const reduceItems = (pageItems) => (
    Object.entries(pageItems)
        .sort(([key1], [key2]) => key1 > key2 ? 1 : -1)
        .reduce((acc, [, value]) => [...acc, ...value], [])
)

export const usePaginatedQuery = (useQuery, {skip, defaultState, defaultData, transformParams} = {}) => {
    const [mainState, setMainState] = useState({page: 1, sort: {}, params: {}, ...defaultState})

    const [dataState, setDataState] = useState({
        ...defaultData,
        items: defaultData?.pageItems ? reduceItems(defaultData.pageItems) : [],
    })

    const queryParams = useMemo(() => ({
        size: PAGE_SIZE,
        page: mainState.page,
        order_by: mainState.sort?.key,
        asc: mainState.sort?.asc,
        ...(transformParams ? transformParams(mainState.params) : mainState.params),

    }), [mainState, transformParams])

    const {data, error, isLoading, isFetching, refetch} = useQuery(queryParams, {skip})

    const total = useMemo(() => data?.total, [data])

    const size = useMemo(() => data?.size, [data])

    const maxPage = useMemo(() => total ? Math.ceil(total / size) : null, [size, total])

    useEffect(() => {
        const loading = isLoading || isFetching

        setDataState((prevState) => {
            // In case request is loading and there is no pageItems we should not use previously set data.
            let actualData = !prevState?.pageItems && loading ? null : data

            const pageItems  = actualData ? {...prevState?.pageItems, [actualData.page]: actualData.items} : prevState?.pageItems || {}
            const items = reduceItems(pageItems)

            return {
                pageItems,
                items,
                loading,
                hasMore: !loading && mainState.page < maxPage,
                empty: !loading && items.length === 0,
            }})

    }, [isFetching, isLoading, mainState.page, data])

    const reset = useCallback(() => {
        setMainState({
            page: 1,
            params: {},
            sort: {},
            pageItems: {},
        })
        refetch()
    }, [])

    const loadNextPage = useCallback(() => {
        if (!dataState.loading && mainState.page < maxPage) {
            setMainState((prev) => ({...prev, page: prev.page + 1}))
        }
    },
    [mainState.page, maxPage, dataState.loading],
    )

    const changeSort = useCallback((newSort) => {
        if (!isEqual(omitBy(newSort, isUndefined), omitBy(mainState.sort, isUndefined))) {
            setDataState({})
            setMainState((prev) => ({...prev, sort: newSort, page: 1}))
        }
    }, [mainState.sort])

    const changeParams = useCallback((params, partial=true) => {
        const baseParams = omitBy(mainState.params, isUndefined)

        let newParams = null
        if (partial) {
            newParams = omitBy({...baseParams, ...params}, isUndefined)
        } else {
            newParams = omitBy(params, isUndefined)
        }

        if (!isEqual(newParams, baseParams)) {
            setDataState({})
            setMainState((prev) => ({...prev, params: newParams, page: 1}))
        }
    }, [mainState.params])

    return {
        items: dataState.items || [],
        loading: dataState.loading,
        empty: dataState.empty,
        hasMore: dataState.hasMore,
        params: mainState.params,
        changeParams,
        sort: mainState.sort,
        changeSort,
        page: mainState.page,
        reset,
        total,
        error,
        maxPage,
        loadNextPage,
        pageItems: dataState.pageItems,
    }
}
