import "./index.scss"

import React from "react"

const VALID_VARIANTS = [
    "header",
    "leading",
    "leading2",
    "supporting",
    "supporting1",
    "supporting2",
]


const getClassName = ({ variant, className }) => {
    const classes = ["ns-typography"]
    if (className) {
        classes.push(className)
    }

    if (VALID_VARIANTS.includes(variant)) {
        classes.push(`ns-typography-${variant}`)
    }

    return classes.join(" ")
}

// variant = "header" | "leading" | "supporting"
const Typography = ({children, variant, className, ...rest}) => {
    const classes =  getClassName({ variant, className })
    return (
        <p className={classes} {...rest}>
            {children}
        </p>
    )
}

export default Typography
