import "./index.scss"

import React, {useMemo, useEffect, useState} from "react"
import Popup from "reactjs-popup"
import Helmet from "react-helmet"
import {useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import Protected from "../../components/Protected/Protected"
import PerformanceReviewsTable from "./components/PerformanceReviewsTable"
import CreatePerformanceReviewPopup from "./components/CreatePerformanceReviewPopup"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PERMISSIONS} from "../../constants/permissions"
import {useLocation} from "react-router-dom"

import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetPerformanceReviewsQuery} from "../../api/performanceReviews"
import {useGetUserPerformanceReviewsQuery} from "../../api/users"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"

const Page = () => {
    const location = useLocation()

    const {hasPermission} = useSelector((state) => state.profile)
    const userId = location.state ? location.state.userId : null

    const [userMessage, setUserMessage] = useState(null)
    const [searchKey, setSearchKey] = useState(location.state?.username ? location.state.username : undefined)
    const [addPerformanceReviewPopup, setAddPerformanceReviewPopup] = useState(false)

    const performanceReviewsQuery = useMemo(
        () => hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW)
            ? useGetPerformanceReviewsQuery
            : useGetUserPerformanceReviewsQuery,
        [hasPermission]
    )

    const {
        items, loading, loadNextPage, hasMore, changeParams, reset, empty
    } = usePaginatedQuery(
        performanceReviewsQuery,
        {
            defaultState: {
                params: {
                    userId: userId,
                    user_full_name: searchKey,
                }
            }
        },
    )

    useEffect(() => changeParams(
        {
            userId: userId,
            user_full_name: searchKey,
        }, false), [userId, searchKey]
    )

    return (
        <>
            <Helmet>
                <title> Performance Reviews - Newsoft Inside </title>
            </Helmet>
            <div className="performance-reviews">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className={`performance-reviews-header${!hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW) ? "-user" : ""}`}>
                    <Protected permissions={[PERMISSIONS.READ_PERFORMANCE_REVIEW]}>
                        <div className="performance-reviews-header-search">
                            <SearchForm onChange={setSearchKey} debounceMs={500} />
                        </div>
                        <Protected permissions={[PERMISSIONS.WRITE_PERFORMANCE_REVIEW]}>
                            <div className="performance-reviews-header-button">
                                <SmallButton onClick={() => {setAddPerformanceReviewPopup(true)}}> ADD PERFORMANCE REVIEW </SmallButton>
                            </div>
                        </Protected>
                    </Protected>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                    scrollableTarget="main-content"
                    useWindow={false}
                >
                    {items.length > 0 &&
                        <div className="performance-reviews-content">
                            <PerformanceReviewsTable
                                performanceReviews={items}
                                refreshTable={reset}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                    {loading && <StyledCircularProgress/>}
                    {empty && <EmptyResult/>}
                </InfiniteScroll>
            </div>

            <Popup open={addPerformanceReviewPopup} closeOnDocumentClick={false} onClose={() => setAddPerformanceReviewPopup(false)} modal>
                {close => (
                    <CreatePerformanceReviewPopup
                        refreshTable={reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default Page
