import React, {useEffect} from "react"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {useSelector} from "react-redux"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {ProfileRoute} from "../../../routes"
import ApproveOnboardingRequestPopup from "./ApproveOnboardingRequestPopup"

import UserImage from "../../../assets/images/user_photo.png"
import Popup from "reactjs-popup"
import {formatDate} from "../../../utils/datetime.utils"
import InfoPopup from "../../../components/InfoPopup"
import {ONBOARDING_STATUS} from "../../../constants/onboarding"
import {PERMISSIONS} from "../../../constants/permissions"
import { MOBILE_WIDTH } from "../../../constants/other"
import { useMediaQuery } from "react-responsive"
import {DD_MM_YYYY} from "../../../constants/other"
import {usePaginatedQuery} from "../../../hooks/common/usePaginatedQuery"
import {useGetOnboardingsQuery} from "../../../api/onboardings"
import InfiniteScroll from "react-infinite-scroll-component"
import CircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"


const OnboardingRequestsTable = ({ searchKey, setUserMessage}) => {
    const history = useCustomHistory()
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})

    const { items, loading, loadNextPage, hasMore, changeParams } = usePaginatedQuery(
        useGetOnboardingsQuery,
        {
            defaultState: { params: {username: searchKey}}
        })

    useEffect(() => changeParams({ username: searchKey}), [searchKey])


    const auth = useSelector((state) => state.auth)
    const {hasPermission} = useSelector((state) => state.profile)

    const renderOnboardingStatus = (onboarding, managerActions) => {
        if (onboarding.onboarding_status === ONBOARDING_STATUS.IN_REVIEW) {
            return hasPermission(PERMISSIONS.WRITE_ONBOARDING) ? managerActions : null
        }
        if (onboarding.onboarding_status === ONBOARDING_STATUS.APPROVED) {
            return <span className="approved-onboarding-requests-span t-h3">APPROVED</span>
        }
        return <span className="declined-onboarding-requests-span t-h3">DECLINED</span>
    }

    const managerActions = (onboarding) => {
        const popupParams = {
            onboarding_request: onboarding,
            setUserMessage: setUserMessage,
        }

        return (
            <>
                <Popup trigger={<span className="custom-scrollable-table-link"> decline </span>} modal>
                    {close => (
                        <ApproveOnboardingRequestPopup
                            {...popupParams}
                            approve={false}
                            close={close}
                        />
                    )}
                </Popup>
                <Popup trigger={<span className="custom-scrollable-table-link"> approve </span>} modal>
                    {close => (
                        <ApproveOnboardingRequestPopup
                            {...popupParams}
                            approve={true}
                            close={close}
                        />
                    )}
                </Popup>
            </>
        )
    }
    
    const onboardingRequestsTable = items.map(onboarding => {
        return (
            <tr key={onboarding.id} className="custom-scrollable-table-row-user">
                <td>
                    <div className="custom-scrollable-table-employee">
                        <span className="custom-scrollable-table-employee-image clickable" onClick={() => profileClick(onboarding.user.id)}>
                            <img src={onboarding.user?.image_url ? onboarding.user.image_url : UserImage} alt="employee"/>
                        </span>
                        <span className="custom-scrollable-table-employee-name clickable" onClick={() => profileClick(onboarding.user.id)}>
                            {onboarding.user.first_name} {onboarding.user.last_name}
                        </span>
                    </div>
                </td>
                <td className="onboarding-requests-content-table-multi-line"></td>
                <td className="onboarding-requests-content-table-multi-line">
                    {formatDate(onboarding.submitted_at, DD_MM_YYYY)}
                </td>
                <td className="onboarding-requests-content-table-multi-line">
                    {onboarding.steps["CHECKED"]}/{onboarding.steps["TOTAL"]}
                </td>
                <td className="onboarding-requests-content-table-multi-line">
                    <Popup trigger={<span className="cell-limited-text"> {onboarding.reason} </span>} modal>
                        {close => (
                            <InfoPopup
                                title="Reason"
                                value={onboarding.reason}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    {renderOnboardingStatus(onboarding, managerActions(onboarding))}
                </td>
            </tr>
        )
    })

    const onboardingRequestsList = items.map(onboarding => {
        return (
            <div key={onboarding.id} className="mobile-table-view-item">
                <div className="mobile-table-view-item-user">
                    <span className="mobile-table-view-item-user-image clickable" onClick={() => profileClick(onboarding.user.id)}>
                        <img src={onboarding.user?.image_url ? onboarding.user.image_url : UserImage} alt="employee"/>
                    </span>
                    <span className="mobile-table-view-item-user-employee-name clickable t-s1" onClick={() => profileClick(onboarding.user.id)}>
                        {onboarding.user.first_name} {onboarding.user.last_name}
                    </span>
                </div>
                <div className="mobile-table-view-item-general">
                    <span className="t-s6">Date</span>
                    <span className="t-b2">{formatDate(onboarding.submitted_at, DD_MM_YYYY)}</span>
                </div>
                <div className="mobile-table-view-item-general">
                    <span className="t-s6">Submitted steps</span>
                    <span className="t-b2">{onboarding.steps["CHECKED"]}/{onboarding.steps["TOTAL"]}</span>
                </div>
                <div className="mobile-table-view-item-general">
                    <span className="t-s6">ACTIONS</span>
                    <div>
                        {renderOnboardingStatus(onboarding, managerActions(onboarding))}
                    </div>
                </div>
                {onboarding.reason && 
                <div className="mobile-table-view-item-general-note">
                    <span className="t-s6">Reason</span>
                    <Popup trigger={<span className="cell-limited-text"> {onboarding.reason} </span>} modal> 
                        {close => (
                            <InfoPopup
                                title="Reason"
                                value={onboarding.reason}
                                close={close}
                            />
                        )}
                    </Popup>
                </div> }
            </div>
        )
    })

    const profileClick = (id) => id === auth.user.uid ? history.push(ProfileRoute.path) : history.push(`/profile/${id}`)

    return (
        <InfiniteScroll
            pageStart={0}
            next={loadNextPage}
            hasMore={hasMore}
            loader={<CircularProgress key="circular-progress"/>}
            dataLength={items.length}
            scrollableTarget={"main-content"}
        >
            {loading? <StyledCircularProgress/> :
                <div className="onboarding-requests-content">
                    {!isMobile ?
                        <CustomScrollableTable>
                            <table className="onboarding-requests-content-table">
                                <thead>
                                    <tr>
                                        <th><span className="custom-scrollable-table-head-span-first">EMPLOYEE</span></th>
                                        <th><span className="custom-scrollable-table-head-span"></span></th>
                                        <th><span className="custom-scrollable-table-head-span">DATE</span></th>
                                        <th><span className="custom-scrollable-table-head-span">SUBMITTED STEPS</span></th>
                                        <th><span className="custom-scrollable-table-head-span">REASON</span></th>
                                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.length > 0 ? onboardingRequestsTable : <EmptyResult/>}
                                </tbody>
                            </table>
                        </CustomScrollableTable> :
                        <div className="mobile-table-view">
                            {items.length > 0 ? onboardingRequestsList : <EmptyResult/>}
                        </div>
                    }
                </div>
            }

        </InfiniteScroll>
    )
}

export default OnboardingRequestsTable