import "./index.scss"
import React, {useCallback, useState} from "react"
import {Helmet} from "react-helmet"
import InfiniteScroll from "react-infinite-scroll-component"
import NewcoinsBalanceTable from "./components/NewcoinsBalanceTable"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import GiveTakeNewcoinsPopup from "./components/GiveTakeNewcoinsPopup"
import Popup from "reactjs-popup"
import EditAutoGivenSettingsPopup from "./components/EditAutoGivenSettingsPopup"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetCoinsBalancesQuery} from "../../api/coinsTransactions"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"


const NewcoinsBalance = () => {
    const [userMessage, setUserMessage] = useState(null)
    const [giveNewcoinsPopup, setGiveNewcoinsPopup] = React.useState(false)
    const [selectedUsers, setSelectedUsers] = React.useState([])
    const [searchKey, setSearchKey] = useState()

    const transformParams = useCallback((params) => ({
        ...params,
        user_name: searchKey,
    }), [searchKey])

    const {
        items,
        loadNextPage,
        loading,
        reset,
        hasMore,
        empty
    } = usePaginatedQuery(useGetCoinsBalancesQuery, { transformParams })

    return (
        <>
            <Helmet>
                <title>Newcoins Balance - Newsoft Inside</title>
            </Helmet>
            <div className="newcoins-balance">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="newcoins-balance-header">
                    <SearchForm onChange={setSearchKey} debounceMs={500} />
                    <div>
                        <Popup trigger={<span className="newcoins-balance-header-settings-label t-b1">Auto-given settings</span>} modal>
                            {close =>
                                <EditAutoGivenSettingsPopup setUserMessage={setUserMessage} close={close}/>
                            }
                        </Popup>
                        <SmallButton disabled={selectedUsers?.length === 0} onClick={() => setGiveNewcoinsPopup(true)}>Give Newcoins</SmallButton>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<StyledCircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                    scrollableTarget={"main-content"}
                >
                    {items.length > 0 &&
                        <div className="newcoins-balance-content">
                            <NewcoinsBalanceTable
                                data={items}
                                selectedUsers={selectedUsers}
                                setSelectedUsers={setSelectedUsers}
                            />
                        </div>
                    }
                    {loading && <StyledCircularProgress/>}
                    {empty && <EmptyResult />}
                </InfiniteScroll>
            </div>
            <Popup open={giveNewcoinsPopup} onClose={() => setGiveNewcoinsPopup(false)}>
                {close => (
                    <GiveTakeNewcoinsPopup
                        give={true}
                        selectedUsers={selectedUsers}
                        refreshTable={() => reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default NewcoinsBalance
