import "../index.scss"

import React, {useEffect, useState} from "react"

import {useDispatch, useSelector} from "react-redux"
import BonusHistoryTable from "./BonusHistoryTable"
import AlertMessage from "../../../toolkits/AlertMessage/AlertMessage"
import {PAGE_SIZE} from "../../../constants/other"
import InfiniteScroll from "react-infinite-scroll-component"
import CircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import {
    receiveNextPageUserBonuses,
    receiveUserBonuses,
} from "../actions/bonusHistoryAction"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import Popup from "reactjs-popup"
import CreateUpdateBonusPopup from "./CreateUpdateBonusPopup"
import Protected from "../../../components/Protected/Protected"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {PERMISSIONS} from "../../../constants/permissions"
import UserImage from "../../../assets/images/user_photo.png"


const BonusHistory = () => {
    const {location: {state}} = useCustomHistory()
    const {
        tableLoading,
        bonuses,
        total,
    } = useSelector((state) => state.bonuses)

    const {accessToEditBonus} = useSelector((state) => state.settings)
    const {myInfo} = useSelector((state) => state.profile)
    const dispatch = useDispatch()

    const [addNewBonusPopup, setAddNewBonusPopup] = useState(false)
    const [userMessage, setUserMessage] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)

    const userId = state?.user.id || myInfo.id
    const username = state ?  `${state.user.first_name} ${state.user.last_name}` : `${myInfo.first_name} ${myInfo.last_name}`
    const imageUrl = state?.user?.image_url || (!state?.user && myInfo.image_url)

    const [editBonusPopup, setEditBonusPopup] = useState(false)

    const getParams = (page) => ({
        page: page || currentPage,
        size: PAGE_SIZE,
    })

    const getUserBonuses = (page) => {
        const params = getParams(page)

        dispatch(receiveUserBonuses(userId, params))
        setCurrentPage((page || currentPage) + 1)
    }

    const getNextUserBonuses = () => {
        const params = getParams()

        dispatch(receiveNextPageUserBonuses(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getUserBonuses(1)
    }, [])

    return (
        <>
            <div>
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="bonus-history-header">
                    <div className="salary-history-header-user">
                        <img alt="User image" src={imageUrl || UserImage}/>
                        <span className="t-s1">{username}</span>
                    </div>
                    <div className="bonus-history-header-button">
                        <Protected permissions={[PERMISSIONS.WRITE_BONUS]}>
                            <SmallButton onClick={() => setAddNewBonusPopup(true)}>ADD NEW BONUS</SmallButton>
                        </Protected>
                    </div>
                </div>
                <Protected permissions={[PERMISSIONS.READ_OWN_BONUS, PERMISSIONS.READ_BONUS]}>
                    {tableLoading ? <CircularProgress key="circular-progress"/> :
                        <div>
                            <InfiniteScroll
                                pageStart={0}
                                next={getNextUserBonuses}
                                hasMore={!tableLoading && bonuses.length < total}
                                loader={<CircularProgress key="circular-progress"/>}
                                dataLength={bonuses.length}
                                scrollableTarget="main-content"
                                useWindow={false}
                            >
                                {tableLoading ? <StyledCircularProgress/> :
                                    <div className="salary-history-content">
                                        <BonusHistoryTable
                                            bonuses={bonuses}
                                            refreshTable={() => getUserBonuses(1)}
                                            setUserMessage={setUserMessage}
                                            accessToEditBonus={accessToEditBonus}
                                        />
                                    </div>
                                }
                            </InfiniteScroll>
                        </div>}
                </Protected>
            </div>
            <Popup
                open={addNewBonusPopup}
                onClose={() => setAddNewBonusPopup(false)}
                modal
            >
                {close => (
                    <CreateUpdateBonusPopup
                        userId={userId}
                        refreshTable={() => {getUserBonuses(1)}}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
            <Popup
                open={editBonusPopup}
                onClose={() => setEditBonusPopup(false)}
                modal
            >
                {close => (
                    <CreateUpdateBonusPopup
                        userId={userId}
                        close={close}
                    />
                )}
            </Popup>
        </>

    )
}

export default BonusHistory
