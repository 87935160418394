import "./index.scss"

import React, {useState, useCallback} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import InfiniteScroll from "react-infinite-scroll-component"

import Protected from "../../components/Protected/Protected"
import ClientsTable from "./components/ClientsTable"
import CreateUpdateClientPopup from "./components/CreateUpdateClientPopup"

import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PERMISSIONS} from "../../constants/permissions"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetClientsQuery} from "../../api/clients"


const Clients = () => {
    const [userMessage, setUserMessage] = useState(null)

    const [searchKey, setSearchKey] = useState("")
    const [createUpdateClientPopup, setCreateUpdateClientPopup] = useState(false)

    const transformParams = useCallback((params) => ({
        ...params,
        name: searchKey,
    }), [searchKey])

    const {
        items,
        loadNextPage,
        loading,
        reset,
        hasMore,
    } = usePaginatedQuery(useGetClientsQuery, { transformParams })

    return (
        <>
            <Helmet>
                <title>Clients - Newsoft Inside</title>
            </Helmet>

            <div className="clients">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="clients-header">
                    <SearchForm onChange={setSearchKey} debounceMs={500}/>
                    <Protected permissions={[PERMISSIONS.WRITE_CLIENT]}>
                        <div className="clients-header-button">
                            <SmallButton onClick={() => setCreateUpdateClientPopup(true)}> ADD NEW CLIENT </SmallButton>
                        </div>
                    </Protected>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                    scrollableTarget={"main-content"}
                >
                    {loading ? <StyledCircularProgress/> :
                        <div className="clients-content">
                            <ClientsTable
                                clients={items}
                                onSubmit={reset}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <Popup
                open={createUpdateClientPopup}
                closeOnDocumentClick={false}
                onClose={() => setCreateUpdateClientPopup(false)}
                modal
            >
                {close => (
                    <CreateUpdateClientPopup
                        onSubmit={reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>)
}

export default Clients
